<template>
  <div class="power_account">>
    <div class="text-h5 text--primary d-flex pa-2 font-weight-bold"> 세력주 평가금액 </div>
    <br>
    <div class="text-title" align="start"> 총 자산: {{ Number(totalAsset).toLocaleString() }} </div>
    <br>
    <v-data-table
      :headers="headers"
      :items="accountList"
      :items-per-page="13"
      class="elevation-1"
      :hide-default-footer="true"
      :loading="loadTable"    
      loading-text="검색 중입니다. 잠시만 기다려 주세요."
    > 
      <template #item.account1="{ item }">        
        {{  Number(item.account1).toLocaleString() }}
      </template>
      <template #item.account2="{ item }">        
        {{  Number(item.account2).toLocaleString() }}
      </template>
      <template #item.account3="{ item }">        
        {{  Number(item.account3).toLocaleString() }}
      </template>
      <template #item.account4="{ item }">        
        {{  Number(item.account4).toLocaleString() }}
      </template>
    </v-data-table>

    <!-- 알림 메세지 -->
    <v-snackbar
    v-model="snackbar"
    :vertical="vertical"
    :color="snackColor"          
    >
    {{ snackText }}
    <template v-slot:action="{ attrs }">
        <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
        >
        Close
        </v-btn>
    </template>
</v-snackbar>

  </div>
</template>

<script>
  import axios from 'axios';
  import {mapState, mapActions, mapMutations} from 'vuex';
  
  export default {
    data () {
      return {
        loadTable: false,
        showChart: false,
        totalAsset: null,
        accountList: [],
        headers: [
          { text: '종목명', value: 'stock_name', align: 'start' },
          { text: '계좌1', value: 'account1', align: 'end' },          
          { text: '계좌2', value: 'account2', align: 'end' },
          { text: '계좌3', value: 'account3', align: 'end' },
          { text: '계좌4', value: 'account4', align: 'end' },
        ],
        
        snackbar: false,
        vertical: true,
        snackColor: null,
        snackText: null,
      }
    },

    computed: {
      ...mapState([ 'isLogin', 'debug', 'user' ])
    },

    created() {
      this.get_account_list();
    },

    methods: {
      get_account_list() {
        var vm = this;
        this.loadTable = true;
        const req_data = {'user': this.user}
        const headers = process.env.VUE_APP_GCP_SHELL ? null : {"Authorization": "jwt "+ localStorage.getItem('jwt')};
        axios.post('/api/power/account/', req_data, {headers})
          .then(function(res) {
            vm.totalAsset = res.data.total_asset; 
            vm.accountList = res.data.data_table;
            vm.loadTable = false;
          })
          .catch(function (err) {
            vm.snackbar = true;
            vm.snackColor = 'error';
            vm.snackText = err;            
          });
      },

    }
  }
</script>

<style>
.power_account{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
